$(document).ready(function () {
    let $elems = $('input:checkbox[data-show-item], input:checkbox[data-hide-item]');

    $elems.each(function () {
        handleCheckbox(this);
    });

    $elems.on('change', function () {
        handleCheckbox(this);
    });
});



function handleCheckbox(checkbox) {
    let $checkbox = $(checkbox);

    if ($checkbox.is(':checked')) {
        $($checkbox.data('show-item')).show();
        $($checkbox.data('hide-item')).hide();

        $($checkbox.data('show-required')).attr('required', 'required');
        $($checkbox.data('hide-required')).removeAttr('required');
    } else {
        $($checkbox.data('show-item')).hide();
        $($checkbox.data('hide-item')).show();

        $($checkbox.data('show-required')).removeAttr('required');
        $($checkbox.data('hide-required')).attr('required', 'required');
    }
}

let $factuur = $('#factuurbedrag');
let $factuurMessage = $('#factuurbedrag_message');
let $messageField = $('#factuurbedrag_message_field');

if ($factuur.length > 0 && $factuurMessage.length > 0) {
    const factuurBedrag = $factuur.data('factuur');
    const offerteBedrag = $factuur.data('offerte');
    const betaaldBedrag = $factuur.data('betaald');

    // if (factuurBedrag > offerteBedrag) {
    //     $factuurMessage.html(getMessage(factuurBedrag, offerteBedrag, betaaldBedrag));
    //     $messageField.show();
    //     $messageField.attr('required', 'required');
    // } else {
    //     $factuur.on('input', function () {
    //         let value = parseFloat($(this).val().replaceAll(',', '.').replace(' ', ''));
    //
    //         if (isNaN(value) || value <= 0 || factuurBedrag + value <= offerteBedrag) {
    //             $factuurMessage.html('');
    //             $messageField.hide();
    //             $messageField.removeAttr('required');
    //
    //         } else {
    //             $factuurMessage.html(getMessage(factuurBedrag, offerteBedrag, betaaldBedrag));
    //             $messageField.show();
    //             $messageField.attr('required', 'required');
    //         }
    //     });
    // }

    let force = false;

    if (factuurBedrag > offerteBedrag) {
        $factuurMessage.html(getMessage(factuurBedrag, offerteBedrag, betaaldBedrag));
        $messageField.show();
        $messageField.find('textarea').attr('required', 'required');

        force = true;
    }

    $factuur.on('input', function () {
        let value = parseFloat($(this).val().replaceAll(',', '.').replace(' ', ''));

        if (!force && (isNaN(value) || value <= 0 || factuurBedrag + value <= offerteBedrag)) {
            $factuurMessage.html('');
            $messageField.hide();
            $messageField.find('textarea').removeAttr('required');
        } else {
            $factuurMessage.html(getMessage(factuurBedrag, offerteBedrag, betaaldBedrag, isNaN(value) ? 0 : value));
            $messageField.show();
            $messageField.find('textarea').attr('required', 'required');
        }
    });


}

function getMessage(factuur, offerte, betaald, add = 0) {
    return `
    <strong>Let op!</strong> Het totale factuur bedrag is hoger dan het offerte bedrag!
    <ul class="list-unstyled">
        <li>
            <strong>Offertebedrag:</strong> €${ bedrag(offerte) }
        </li>
        <li>
            <strong>Totale factuurbedrag:</strong> €${ bedrag(factuur + add) }
        </li>
        <li>
            <strong>Betaald:</strong> €${ bedrag(betaald) }
        </li>
    </ul>`;
}

function bedrag(bedrag) {
    let bed = (Math.round(bedrag * 100) / 100).toFixed(2);

    bed = bed.replace('.', ',');
    bed = bed.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return bed;
}

