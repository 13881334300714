/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

// global.$ = require("jquery"); // jquery fix

// window.Vue = require('vue');
//
// /**
//  * The following block of code may be used to automatically register your
//  * Vue components. It will recursively scan this directory for the Vue
//  * components and automatically register them with their "basename".
//  *
//  * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
//  */
//
// // const files = require.context('./', true, /\.vue$/i)
// // files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
//
// Vue.component('example-component', require('./components/ExampleComponent.vue').default);
//
// /**
//  * Next, we will create a fresh Vue application instance and attach it to
//  * the page. Then, you may begin adding components to this application
//  * or customize the JavaScript scaffolding to fit your unique needs.
//  */
//
// const app = new Vue({
//     el: '#app',
// });

require('datatables.net/js/jquery.dataTables');
require('datatables.net-bs4/js/dataTables.bootstrap4');
require('bootstrap-notify');
require('block-ui');
require('bootstrap-select');
require('bootstrap-datepicker');
require('jquery-mask-plugin');

require('../../public/js/datatables/tables');
require('../../public/js/custom/datepicker');
require('../../public/js/custom/selection');
require('../../public/js/custom/inputmask');
require('../../public/js/custom/statuspicker');
require('../../public/js/custom/custom');
require('../../public/js/custom/copy');
require('../../public/js/custom/aanvraag');

require('@fancyapps/fancybox');
// require('../../public/js/import_wizard');
var csv = require('jquery-csv');
