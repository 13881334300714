let lastValue;

$(document).ready(function () {
    $('.selectpicker').selectpicker();
    let select = $('#type_kosten_select');
    let field = $('#type_kosten_field');

    if (select.length > 0 && field.length > 0) {
        lastValue = isOtherSelected(select.val()) ? field.val() : '';

        handleField(select, field);

        select.on('change', function () {
            handleField(select, field);
        });

        field.on('input', function () {
            lastValue = field.val();
        });
    }
});



function handleField(select, field) {
    let value = select.val();

    if (isOtherSelected(value)) {
        field.val(lastValue);
        field.attr('type', 'text');
    } else {
        field.val(value);
        field.attr('type', 'hidden');
    }
}

function isOtherSelected(value) {
    return value === 'field_other';
}
