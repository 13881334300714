
var arrows;
if (KTUtil.isRTL()) {
    arrows = {
        leftArrow: '<i class="la la-angle-right"></i>',
        rightArrow: '<i class="la la-angle-left"></i>'
    }
} else {
    arrows = {
        leftArrow: '<i class="la la-angle-left"></i>',
        rightArrow: '<i class="la la-angle-right"></i>'
    }
}

$.fn.datepicker.dates['en'] = {
    days: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag", "zondag"],
    daysShort: ["zon", "maa", "din", "woe", "don", "vri", "zat", "zon"],
    daysMin: ["zo", "ma", "di", "wo", "do", "vr", "za", "zo"],
    months: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
    monthsShort: ["Jan", "Feb", "Maa", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
    today: "Vandaag",
    clear: "Leegmaken",
    format: "dd-mm-yyyy",
    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
    weekStart: 0
};

$(document).ready(function () {
    $('.dob-picker, .datepicker').each(function () {
        $(this).datepicker({
            rtl: KTUtil.isRTL(),
            todayBtn: "linked",
            todayHighlight: true,
            templates: arrows,
            orientation: "bottom left",
        });
    });
});
