import KTDatatable from '../scripts.bundle';
if (window.location.origin.includes("localhost")) {
    var sitelink = "http://localhost/nijhuis/public";
} else {
    var sitelink = window.location.origin;
}

(function( factory ) {
    "use strict";

    if ( typeof define === 'function' && define.amd ) {
        // AMD
        define( ['jquery'], function ( $ ) {
            return factory( $, window, document );
        } );
    }
    else if ( typeof exports === 'object' ) {
        // CommonJS
        module.exports = function (root, $) {
            if ( ! root ) {
                root = window;
            }

            if ( ! $ ) {
                $ = typeof window !== 'undefined' ?
                    require('jquery') :
                    require('jquery')( root );
            }

            return factory( $, root, root.document );
        };
    }
    else {
        // Browser
        factory( jQuery, window, document );
    }
}
(function( $, window, document ) {


    $.fn.dataTable.render.moment = function ( from, to, locale ) {
        // Argument shifting
        if ( arguments.length === 1 ) {
            locale = 'en';
            to = from;
            from = 'YYYY-MM-DD';
        }
        else if ( arguments.length === 2 ) {
            locale = 'en';
        }

        return function ( d, type, row ) {
            if (! d) {
                return type === 'sort' || type === 'type' ? 0 : d;
            }

            var m = window.moment( d, from, locale, true );

            // Order and type get a number value from Moment, everything else
            // sees the rendered value
            return m.format( type === 'sort' || type === 'type' ? 'x' : to );
        };
    };


}));

const lang = {
        processing: "Bezig...",
        "sLengthMenu": "_MENU_ resultaten weergeven",
        "sZeroRecords": "Geen resultaten gevonden",
        "sInfo": "_START_ tot _END_ van _TOTAL_ resultaten",
        "sInfoEmpty": "Geen resultaten om weer te geven",
        "sInfoFiltered": " (gefilterd uit _MAX_ resultaten)",
        "sInfoPostFix": "",
        "sSearch": "Zoeken:",
        "sEmptyTable": "Geen resultaten aanwezig in de tabel",
        "sInfoThousands": ".",
        "sLoadingRecords": "<div class=\"circleloader\" style='margin: 0 auto;'></div>",
        "oPaginate": {
            "sFirst": "Eerste",
            "sLast": "Laatste",
            "sNext": "Volgende",
            "sPrevious": "Vorige"
        },
        "oAria": {
            "sSortAscending": ": activeer om kolom oplopend te sorteren",
            "sSortDescending": ": activeer om kolom aflopend te sorteren"
        }
    };

const length = [[10, 25, 50, 100, 250], [10, 25, 50, 100, 250]];

$(document).ready(function(){
    $('table[data-table-url]').each(function () {
        table($(this));
    });
});

function table(element){
    let url = element.data('table-url');
    let order = element.data('table-order') || [];
    let columns = element.data('table-columns');

    // console.log(url)

    for (let i = 0; i < columns.length; i++) {
        let v = columns[i];
        if (v.moment) {
            v.render = $.fn.dataTable.render.moment( 'X', v.moment, 'nl');
        }
    }

    element.DataTable({
        "scrollX": true,
        stateSave: false,
        "pageLength": 10,
        "order": order,
        responsive: false,
        "ajax": url,
        "lengthMenu": length,
        columns: columns,
        "columnDefs": [
            {
                sClass: "hidden",
                visible: false,
                "targets": [0],
                searchable: true,
            },
        ],
        "language": lang,
        "initComplete": function () {},
        // "scrollX": true
    });
}


