$(document).ready(function () {
    $('[data-copy-value]').on('click', function (e) {
        e.preventDefault();

        let text = $(this).data('copy-value');

        copy(text);
    })
});

function copy(text) {
    let $body = $('body');
    const $temp = $(`<input type="text" value="${text}">`);
    $body.append($temp);
    $temp.select();
    document.execCommand("Copy");
    $temp.remove();
}
