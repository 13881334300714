let lastValue, lastText;

$(document).ready(function () {
    $('.selectpicker').selectpicker();
    let select = $('#form_status_toelichting_select');
    let field = $('#form_status_toelichting_field');

    if (select.length > 0 && field.length > 0) {
        lastValue = select.val();
        lastText = field.text();

        handleField(select, field);

        select.on('change', function () {
            handleField(select, field);
        });
    }
});



function handleField(select, field) {
    let value = select.val();

    field.text(lastValue == value ? lastText : '');
}
