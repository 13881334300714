$(document).ready(function () {
    let $textField = $('#aanvraag_vragen_toelichting');
    let $check = $('input[name*="aanvraag_question_"]');
    let $button = $('button[type="submit"]');
    if ($textField.length > 0) {
        handleQuestionFields($check, $textField);

        $check.on('click', function (e) {
            handleQuestionFields($check, $textField);
        })
    }
});

function handleQuestionFields($check, $textField) {
    let hide = false;
    $check.each(function () {
        let $this = $(this);

        if ($this.is(':checked') && $this.val() == "nee") {
            hide = true;
        }
    });

    if (hide) {
        $textField.show();
        $textField.find('textarea').attr('required', 'required');
    } else {
        $textField.hide();
        $textField.find('textarea').removeAttr('required');

    }
}

function allChecked($check) {
    let check = true;

    $check.each(function () {
        let $toCheck = $(`input[name="${$(this).attr('name')}"]:checked`);

        if ($toCheck.length <= 0) {
            check = false;
        }
    });

    return check;
}
